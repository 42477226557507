import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import { sanitizeMessageContent, hasHTML } from './utils';
import { ChatBubble } from './styled-components';

const MessageContent = React.memo(({ role, content }) => {
  if (role === 'user') {
    return <ChatBubble $role={role}>{content}</ChatBubble>;
  } else {
    if (hasHTML(content)) {
      return (
        <ChatBubble
          $role={role}
          dangerouslySetInnerHTML={{
            __html: sanitizeMessageContent(content),
          }}
        />
      );
    } else {
      return (
        <ChatBubble $role={role}>
          <Markdown remarkPlugins={[remarkGfm]}>{content}</Markdown>
          {role === 'assistant' && (
            <button
              style={{
                background: 'transparent',
                border: 'none',
                cursor: 'pointer',
                width: '12px',
              }}
              onClick={() => {
                navigator.clipboard.writeText(content);
              }}
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.5 1.5H4.5C3.4 1.5 2.5 2.4 2.5 3.5V17.5H4.5V3.5H16.5V1.5ZM19.5 5.5H8.5C7.4 5.5 6.5 6.4 6.5 7.5V21.5C6.5 22.6 7.4 23.5 8.5 23.5H19.5C20.6 23.5 21.5 22.6 21.5 21.5V7.5C21.5 6.4 20.6 5.5 19.5 5.5ZM19.5 21.5H8.5V7.5H19.5V21.5Z"
                  fill="#C205FF"
                />
              </svg>
            </button>
          )}
        </ChatBubble>
      );
    }
  }
});

export default MessageContent;
